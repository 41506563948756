import React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import SectionWrapper from "../components/sections/section-wrapper"

const Program = () => {
    const programs = [
        {
            time: "09:00 - 09:10",
            activity: "Welcome Remarks",
            speaker: "Jacqueline Tate",
            speakerDescription: "Chair, ACM-W AP Committee",
            speakerLink: null,
            subSpeakerTitle: null,
            subSpeaker: null,
            subSpeakerDescription: null
        },
        {
            time: "09:10 - 09:45",
            activity: "Keynote Speaker 1",
            talkTitle: "Graph Representation Learning for Drug Discovery",
            speaker: "Dr. Ming Zhang",
            speakerDescription: "Professor - Peking University, China",
            speakerLink: "http://localhost:8000/keynote/#mingzhang",
        },
        {
            time: "09:45 - 10:20",
            activity: "Keynote Speaker 2",
            talkTitle: "Humans vs Automation: Designing and Implementing Automated Mechanisms with (un)Predictable Humans",
            speaker: "Frei Sangil",
            speakerDescription: "Founder / CEO - LAYERTech Software Labs (Inc.)",
            speakerLink: "/keynote/#freisangil",
            subSpeakerTitle: "Chair",
            subSpeaker: "Dr. Lany Laguna-Maceda",
            subSpeakerDescription: "Bicol University - Philippines"
        },
        {
            time : "10:20 - 10:55",
            activity : "Keynote Speaker 3",
            talkTitle: "Thinking of an Equal World",
            speaker : "Dr. Nova Ahmed",
            speakerDescription : "North South University, Bangladesh",
            speakerLink : "/keynote/#novahmed",
            subSpeakerTitle : "Chair",
            subSpeaker : "Dr. Bimlesh Wadhwa",
            subSpeakerDescription : "National University of Singapore",
        },
        {
          time: "10:55 - 11:35",
          activity: "Presentation Session",
          speaker: "Selected Abstracts",
          speakerDescription: null,
          speakerLink: null,
          subSpeakerTitle: null,
          subSpeaker: null,
          subSpeakerDescription: null
        },
        {
          time: "11:35 - 11:55",
          activity: "Presentation of Shining and Rising Star Awards",
          speaker: "Dr. Bimlesh Wadhwa",
          speakerDescription: "Chair, Shining and Rising Star Awards",
          speakerLink: null,
          subSpeakerTitle: null,
          subSpeaker: null,
          subSpeakerDescription: null
        },
        {
          time: "11:55 - 12:05",
          activity: "Closing Remarks",
          speaker: "Dr. Rachel Edita Roxas",
          speakerDescription: "Conference Chair, ACM-W AP Celebration",
          speakerLink: null,
          subSpeakerTitle: null,
          subSpeaker: null,
          subSpeakerDescription: null
        },
        {
          time: "12:05 - 13:05",
          activity: "After Plenary Activity",
          speaker: null,
          speakerDescription: null,
          speakerLink: "https://acmwap2021.boards.net/",
          subSpeakerTitle: null,
          subSpeaker: null,
          subSpeakerDescription: null
        },
    ]
  return (
    <Layout>
      <Navigation />
      <SectionWrapper bgColor="bg-primary">
        <h1 className="mt-16 mb-6 md:mb-10 text-center text-4xl font-black text-white">Programme</h1>
        <div className="flex-wrap mb-4 md:mb-6 w-full lg:w-9/12 mx-auto flex justify-center">
          <div className="rounded-full bg-accent text-white px-4 py-2 shadow-md mx-2 mb-2 md:mb-0" data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="200">
            April 7, 2021
          </div>
          <div className="rounded-full bg-accent text-white px-4 py-2 shadow-md mx-2 mb-2 md:mb-0" data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="400">
            Manila Time - 09:00 AM (9:00)
          </div>
          <div className="rounded-full bg-accent text-white px-4 py-2 shadow-md mx-2 mb-2 md:mb-0" data-sal="slide-left" data-sal-easing="ease" data-sal-duration="600" data-sal-delay="600">
            UTC - 01:00 AM (1:00)
          </div>
        </div>
        <div className="mb-16 px-8 py-6 rounded-xl w-full lg:w-9/12 mx-auto bg-white shadow hover:shadow-md">
          <div className={"flex justify-center pt-4 pb-8"}>
            <a className="tracking-wide leading-snug text-lg font-semibold custom-hover-effect w-auto px-2">
              Event concluded successfully!
            </a>
          </div>
          {programs.map((program, i) => {
            return (
              <ProgramItem
                time={program.time}
                activity={program.activity}
                talkTitle={program.talkTitle}
                speaker={program.speaker}
                subSpeakerTitle={program.subSpeakerTitle}
                speakerDescription={program.speakerDescription}
                speakerLink={program.speakerLink}
                subSpeaker={program.subSpeaker}
                subSpeakerDescription={formatNewline(program.subSpeakerDescription)}
                isLast={i === programs.length - 1}
              />
            )
          })}
        </div>
      </SectionWrapper>
      <Footer />
    </Layout>
  )
}

function formatNewline(text) {
  let out = text;
  if(out != null) out = out.split("\n").map(str => <>{str}<br /></>)
  return out;
}

const ProgramItem = ({
  time,
  activity,
  talkTitle,
  speaker,
  subSpeakerTitle,
  speakerDescription,
  speakerLink,
  subSpeaker,
  subSpeakerDescription,
  isLast,
}) => (
  <div
    className={`${!isLast && "border-b"} py-6 flex items-center flex-wrap`}
    data-sal="fade"
    data-sal-duration="600"
    data-sal-delay="300"
  >
    <div className="text-primary-light md:text-black tracking-wide text-center text-lg font-semibold w-full md:w-1/2">
      {time}
    </div>
    <div className="text-center w-full md:w-1/2 cursor-default">
      <Link
        to={speakerLink}
        className={speakerLink == null && "cursor-default"}
      >
        <h2 className="tracking-wide text-lg font-semibold">{activity}</h2>
        <h4 className={`${talkTitle != null && "mb-2"} tracking-wide text-sm text-primary font-bold`}>
          {talkTitle}
        </h4>
        <h3 className="tracking-wide text-sm">{speaker}</h3>
        <h4 className="tracking-wide text-xs text-gray-500">
          {speakerDescription}
        </h4>
      </Link>
      <h4
        className={`${
          subSpeaker != null && "mt-6"
        } "tracking-wide text-xs font-semibold`}
      >
        {subSpeakerTitle}
      </h4>
      <h3 className="tracking-wide text-sm">{subSpeaker}</h3>
      <h4 className="tracking-wide text-xs text-gray-500">
        {subSpeakerDescription}
      </h4>
    </div>
  </div>
)

export default Program
